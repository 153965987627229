<template>
  <div class="stop"></div>
</template>

<style lang="less" scoped>
.stop {
  width: 100vw;
  height: 100vh;
  background-image: url("/static/images/close.png");
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
}
</style>