
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import layout from "../components/layout.vue";

export default defineComponent({
  components: { layout },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      msg: "数据加载中...",
      current_stage_num: "1",
      current_stage_name: "第一阶段",
      isLoading: true,
      dataList: [] as any[],
    });

    const getNirvanaList = () => {
      store
        .dispatch("actGetNirvanaList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          nirvana_type: "ALL_DEPART",
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.current_stage_num = res.data_list.current_stage_num;
            dataMap.current_stage_name = res.data_list.current_stage_name;
            for (let key in res.data_list.list) {
              let group = res.data_list.list[key];
              let tem = [];
              for (let x in group) {
                tem.push(group[x]);
              }
              dataMap.dataList.push(tem);
            }
            if (dataMap.dataList.length === 0) {
              dataMap.msg = "暂无数据";
            }
            dataMap.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getNirvanaList();
    return {
      ...toRefs(dataMap),
    };
  },
});
