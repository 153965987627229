import { Directive } from 'vue'

/**
 * binding.value:
 *    top:顶部距离,默认 100px(宽：375px)
 *    bottom:底部距离,默认 0px(宽：375px)
 *    left:左侧距离,默认 0px(宽：375px)
 *    right:右侧距离,默认 0px(宽：375px)
 *    width: 元素的宽,默认 40px(宽：375px)
 *    height:元素的高,默认 40px(宽：375px)
 */
export const moveDom: Directive<HTMLElement> = {
  mounted(el, binding) {
    const { top = 100, bottom = 0, left = 0, right = 0, width = 40, height = 40 } = binding.value || {}
    let dragEl = el;
    let BODY_WIDTH = document.documentElement.clientWidth;
    let BODY_HEIGHT = document.documentElement.clientHeight;

    let WIDTH = width / 375 * BODY_WIDTH
    let HEIGHT = height / 375 * BODY_WIDTH
    let UP = top / 375 * BODY_WIDTH
    let DOWN = bottom / 375 * BODY_WIDTH

    dragEl.addEventListener(
      "touchstart",
      function (e: any) {
        document.body.addEventListener("touchmove", function (e) { }, false);
      },
      false
    );

    dragEl.addEventListener(
      "touchmove",
      function (e: any) {
        let targetTouches = e.targetTouches[0];
        let clientX = targetTouches.clientX
        let clientY = targetTouches.clientY
        if (clientX < left) {
          clientX = left;
        }
        if (clientX + WIDTH > BODY_WIDTH - right) {
          clientX = BODY_WIDTH - right - WIDTH
        }
        if (clientY < UP) {
          clientY = UP
        }
        if (clientY + HEIGHT > BODY_HEIGHT - DOWN) {
          clientY = BODY_HEIGHT - DOWN - HEIGHT
        }
        el.style.left = clientX + "px";
        el.style.top = clientY + "px";
        dragEl.addEventListener(
          "touchend",
          function (e: any) {
            document.body.removeEventListener(
              "touchmove",
              function (e) {
                e.preventDefault();
              },
              false
            );
          },
          false
        );
      },
      false
    );
  },
}
