
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";

export default defineComponent({
  props: {
    nirvana_type: {
      type: String,
      defualt: "",
    },
  },
  setup(props) {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;
    const dataMap = reactive({
      msg: "数据加载中...",
      isLoading: true,
      data_list: {} as GetNirvanaListReq1,
    });
    const getNirvanaList = () => {
      store
        .dispatch("actGetNirvanaList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          nirvana_type: props.nirvana_type!,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.isLoading = false;
            dataMap.data_list = res.data_list;
            console.log();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getNirvanaList();
    return {
      ...toRefs(dataMap),
    };
  },
});
