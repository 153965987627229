
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import layout from "../components/layout.vue";

export default defineComponent({
  components: {
    layout,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      msg: "数据加载中...",
      isLoading: true,
      dataList: [] as NirvanaList[],
    });

    const getNirvanaList = () => {
      store
        .dispatch("actGetNirvanaList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          nirvana_type: "REGION_SZ",
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.isLoading = false;
            dataMap.dataList = res.data_list;
            if (dataMap.dataList.length === 0) {
              dataMap.msg = "暂无数据";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getNirvanaList();
    return {
      ...toRefs(dataMap),
    };
  },
});
