<template>
  <div class="layout hidden-scrollbar" :style="{ background: bgColor }">
    <div class="pic" v-if="!!src"><img :src="src" alt="" /></div>
    <slot>
    </slot>
    <div class="loading" v-if="isLoading">
      <p class="text-c">{{ msg }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
      default: "/static/images/pk-top2.png"
    },
    msg: {
      type: String,
      default: "数据加载中...",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: "#ba1118"
    }
  },
  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
.layout {
  height: 100vh;
  width: 100vw;
  overflow: auto;

  .pic {
    font-size: 0;
  }

  .loading {
    font-size: 36px;
    color: #fff;

    p {
      margin-top: 20vh;
    }
  }
}
</style>