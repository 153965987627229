<template>
  <div class="allPersonal">
    <layout :isLoading="isLoading" :msg="msg">
      <div v-if="dataList.length !== 0" class="branch">
        <section v-if="dataList[0] && dataList[0].length > 0" class="p-t-20">
          <div class="stage-title">{{ current_stage_name }}</div>
          <template v-for="(value, index) in dataList[0]" :key="index">
            <div :class="['row', 'row-' + (index + 1)]">
              <div class="index">{{ index + 1 }}</div>
              <div class="name flex-1 p-b-10 f-s-12">{{ value.name }}</div>
              <div class="score">{{ value.score }}分</div>
            </div>
          </template>
        </section>
        <section v-if="current_stage_num === '2' && dataList[1] && dataList[1].length" class="p-t-20">
          <div class="stage-title">总阶段</div>
          <template v-for="(item, index) in dataList[1]" :key="index">
            <div :class="['row', 'row-' + (index + 1)]">
              <div class="index">{{ index + 1 }}</div>
              <div class="name flex-1 p-b-10 f-s-12">{{ item.name }}</div>
              <div class="score">{{ item.score + "分" }}</div>
            </div>
          </template>
        </section>
        <section v-if="current_stage_num === '1'" class="p-t-20">
          <div class="stage-title">总阶段</div>
          <div>
            <div class="empty">
              <p>-</p>
            </div>
            <div class="empty">
              <p>-</p>
            </div>
            <div class="empty">
              <p>-</p>
            </div>
          </div>
        </section>
      </div>
    </layout>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import layout from "../components/layout.vue";

export default defineComponent({
  components: { layout },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      msg: "数据加载中...",
      current_stage_num: "1",
      current_stage_name: "第一阶段",
      isLoading: true,
      dataList: [] as any[],
    });

    const getNirvanaList = () => {
      store
        .dispatch("actGetNirvanaList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          nirvana_type: "ALL",
        })
        .then((res) => {
          if (res.status === 200) {
            dataMap.current_stage_num = res.data_list.current_stage_num;
            dataMap.current_stage_name = res.data_list.current_stage_name;
            dataMap.dataList.push(
              res.data_list.list?.[res.data_list.current_stage_num]
            );
          }
          dataMap.dataList.push(res.data_list.list?.["0"]);
          if (dataMap.dataList.length === 0) {
            dataMap.msg = "暂无数据";
          }
          dataMap.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getNirvanaList();
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.branch {
  width: 300px;
  padding: 0 0 40px;
  margin: 10px auto 40px;

  section {
    .stage-title {
      color: #fff;
      line-height: 2rem;
      text-align: center;
      font-size: 15px;
      font-weight: 700;
    }

    .empty p {
      height: 3rem;
      line-height: 3rem;
      width: 100%;
      font-weight: 700;
      color: #ffeb67;
      text-align: center;
    }
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;
  font-weight: 700;

  .index,
  .name,
  .score {
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
  }

  .index {
    flex: 0 0 25px;
    text-align: center;
  }

  .score {
    flex: 0 0 70px;
    text-align: center;
  }
}

.row-1 {
  color: #ffeb67;
}

.row-3 {
  color: #dbd9d9;
}

.row-2 {
  color: #cab76b;
}
</style>