<template>
  <div class="region-sz">
    <layout :msg="msg" :isLoading="isLoading">
      <div v-if="dataList.length !== 0" class="p-b-20">
        <div v-for="(item, index) in dataList" :key="index" class="node m-t-50">
          <p class="title text-c f-c-f f-s-18 f-w-7 m-b-10 font-line-height-35">
            {{ item.title }}
          </p>
          <div
            v-for="(el, i) in item.data"
            :key="'el' + i"
            class="p-h-20 f-c-f flex flex-wrap-no"
          >
            <div class="flex-1 font-line-height-25">{{ el.name }}</div>
            <div class="flex-1 text-r font-line-height-25">
              {{ el.score }}
              <span class="font-10">分</span>
            </div>
          </div>
        </div>
      </div>
    </layout>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import layout from "../components/layout.vue";

export default defineComponent({
  components: {
    layout,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      msg: "数据加载中...",
      isLoading: true,
      dataList: [] as NirvanaList[],
    });

    const getNirvanaList = () => {
      store
        .dispatch("actGetNirvanaList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          nirvana_type: "REGION_SZ",
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.isLoading = false;
            dataMap.dataList = res.data_list;
            if (dataMap.dataList.length === 0) {
              dataMap.msg = "暂无数据";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getNirvanaList();
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.node {
  .title {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
}
</style>