<template>
  <div class="new-region">
    <div class="logo-start">
      <img src="/static/images/newRegionStart.jpg" alt="" />
    </div>
    <div class="main">
      <div v-if="!isLoading">
        <div>
          <div v-for="(group, index) in data_list.war_list" :key="index">
            <div class="title">
              <span>战区</span>
              <span>{{ data_list.current_stage_name }}</span>
              <span>总阶段</span>
            </div>
            <div v-for="(item, idx) in group" :key="idx" class="content">
              <span>{{ item.name }}</span>
              <span>{{ item.score }}</span>
              <span>{{ item.all_score }}</span>
            </div>
            <br />
          </div>
        </div>
        <div>
          <div v-for="(group, index) in data_list.region_list" :key="index">
            <div class="title">
              <span>区域</span>
              <span>{{ data_list.current_stage_name }}</span>
              <span>总阶段</span>
            </div>
            <div v-for="(item, idx) in group" :key="idx" class="content">
              <span>{{ item.name }}</span>
              <span>{{ item.score }}</span>
              <span>{{ item.all_score }}</span>
            </div>
            <br />
          </div>
        </div>
        <div>
          <div v-for="(group, index) in data_list.org_list" :key="index">
            <div class="title">
              <span>分公司</span>
              <span>{{ data_list.current_stage_name }}</span>
              <span>总阶段</span>
            </div>
            <div v-for="(item, idx) in group" :key="idx" class="content">
              <span>{{ item.name }}</span>
              <span>{{ item.score }}</span>
              <span>{{ item.all_score }}</span>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div class="loading" v-if="isLoading">
        <p class="text-c">{{ msg }}</p>
      </div>
    </div>
    <div class="logo-end">
      <img src="/static/images/newRegionEnd.jpg" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";

export default defineComponent({
  props: {
    nirvana_type: {
      type: String,
      defualt: "",
    },
  },
  setup(props) {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;
    const dataMap = reactive({
      msg: "数据加载中...",
      isLoading: true,
      data_list: {} as GetNirvanaListReq1,
    });
    const getNirvanaList = () => {
      store
        .dispatch("actGetNirvanaList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          nirvana_type: props.nirvana_type!,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.isLoading = false;
            dataMap.data_list = res.data_list;
            console.log();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getNirvanaList();
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.new-region {
  .logo-start,
  .logo-end {
    font-size: 0;
    img {
      width: 100%;
    }
  }
  .main {
    width: 100%;
    min-height: calc(100vh - 355.5px);
    background: #b23b00;
    padding: 0 20px;
    .title {
      display: flex;
      span {
        display: inline-block;
        color: #7f7f91;
        font-weight: 800;
        font-size: 20px;
        text-align: center;
        &:nth-child(1) {
          flex: 0 0 45%;
        }
        &:nth-child(2) {
          flex: 0 0 27.5%;
        }
        &:nth-child(3) {
          flex: 0 0 27.5%;
        }
      }
    }
    .content {
      display: flex;
      span {
        display: inline-block;
        color: #bf9000;
        font-size: 16px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        &:nth-child(1) {
          font-weight: 700;
          flex: 0 0 45%;
        }
        &:nth-child(2) {
          flex: 0 0 27.5%;
        }
        &:nth-child(3) {
          flex: 0 0 27.5%;
        }
      }
    }
  }
  .loading {
    font-size: 36px;
    color: #fff;
  }
}
</style>