
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";
import layout from "./components/layout.vue";
import stopPage from "./views/stopPage.vue";
import allPersonal from "./views/allPersonal.vue";
import allRegion from "./views/allRegion.vue";
import regionSZ from "./views/regionSZ.vue";
import departRegion from "./views/departRegion.vue";
import departPersonal from "./views/departPersonal.vue";
import newRegion from "./views/newRegion.vue";
import regionJune from "./views/regionJune.vue";
import { moveDom } from "@/directives/moveDom";

export default defineComponent({
  components: {
    layout,
    stopPage,
    allPersonal,
    allRegion,
    regionSZ,
    departRegion,
    departPersonal,
    newRegion,
    regionJune,
  },
  setup() {
    const router = useRouter();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      move: {} as HTMLElement,
      msg: "数据加载中...",
      isLoading: true,
      isShowIcon: false,
      page_type: "",
      nirvana_type: "",
      dataList: [] as {
        name: string;
        page_type: string;
        nirvana_type: string;
      }[],
    });

    const getNirvanaConfig = () => {
      store
        .dispatch("actGetNirvanaConfig", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.page_type = res.data_list.page_type;
            dataMap.dataList = res.data_list.nirvana_arr;
            if (dataMap.dataList.length === 0) {
              dataMap.msg = "暂无数据";
            }
            if (dataMap.page_type !== "arr") {
              dataMap.nirvana_type = res.data_list.nirvana_arr[0].nirvana_type;
            }
            dataMap.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleClick = (item: {
      name: string;
      page_type: string;
      nirvana_type: string;
    }) => {
      dataMap.page_type = item.page_type;
      dataMap.nirvana_type = item.nirvana_type;
      dataMap.isShowIcon = true;
    };

    const back = () => {
      dataMap.page_type = "arr";
      dataMap.isShowIcon = false;
      dataMap.move.style.left = "unset";
      dataMap.move.style.top = "80%";
      dataMap.move.style.right = "20px";
    };

    getNirvanaConfig();
    return {
      ...toRefs(dataMap),
      handleClick,
      back,
    };
  },
  directives: {
    move: moveDom,
  },
});
