<template>
  <div class="allRegion">
    <layout :isLoading="isLoading" :msg="msg">
      <div v-if="dataList.length !== 0">
        <div
          class="group p-v-20"
          v-for="(group, ids) in dataList"
          :key="'group' + ids"
        >
          <section>
            <div class="area-title">
              <div class="area-title-left">{{ current_stage_name }}</div>
              <div class="area-title-right">总阶段</div>
            </div>
            <div v-for="(area, index) in group" :key="'area' + index">
              <div :class="['node', index == group.length - 1 ? 'end' : '']">
                <div v-for="(item, ids) in area" :key="ids">
                  <div :class="['row', 'row-' + (ids + 1)]">
                    <div class="index">{{ ids + 1 }}</div>
                    <div class="text">
                      {{ item.name + "(" + item.score + "分)" }}
                    </div>
                    <div class="total">
                      {{
                        item.all_score + (current_stage_num == "2" ? "分" : "")
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </layout>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import layout from "../components/layout.vue";

export default defineComponent({
  components: { layout },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      msg: "数据加载中...",
      current_stage_num: "1",
      current_stage_name: "第一阶段",
      isLoading: true,
      dataList: [] as any[],
    });

    const getNirvanaList = () => {
      store
        .dispatch("actGetNirvanaList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          nirvana_type: "ALL",
        })
        .then((res) => {
          if (res.status == 200) {
            dataMap.current_stage_num = res.data_list.current_stage_num;
            dataMap.current_stage_name = res.data_list.current_stage_name;
            for (let key in res.data_list.list) {
              let group = res.data_list.list[key];
              let tem = [];
              for (let x in group) {
                tem.push(group[x]);
              }
              dataMap.dataList.push(tem);
            }
            if (dataMap.dataList.length === 0) {
              dataMap.msg = "暂无数据";
            }
            dataMap.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getNirvanaList();
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.group {

  section {
    padding: 10px auto 40px;
    .area-title {
      padding: 10px 0 0;
      width: 307px;
      display: flex;
      flex-wrap: nowrap;
      font-weight: 700;
      margin: 0 34px;
      border: 1px solid #fff;
      border-radius: 10px 10px 0 0;
      .area-title-left,
      .area-title-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: #fff;
        line-height: 2rem;
        font-size: 16px;
      }

      .area-title-left {
        flex: 1;
      }
      .area-title-right {
        flex: 0 0 75px;
        text-align: center;
      }
    }
    .node {
      padding: 10px 0;
      margin: 0 34px;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    .end {
      border-radius: 0 0 10px 10px;
    }
  }
}
.row {
  display: flex;
  flex-wrap: nowrap;
  font-weight: 700;
  .index,
  .name,
  .score,
  .text,
  .total {
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
  }

  .index {
    flex: 0 0 25px;
    text-align: center;
  }
  .name {
    flex: 1;
    padding: 0 0 0 10px;
  }
  .score {
    flex: 0 0 70px;
    text-align: center;
  }
  .text {
    flex: 1;
  }
  .total {
    flex: 0 0 75px;
    text-align: center;
  }
}
.row-1 {
  color: #ffeb67;
}
.row-3 {
  color: #dbd9d9;
}
.row-2 {
  color: #cab76b;
}
</style>