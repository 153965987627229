
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
      default: "/static/images/pk-top2.png"
    },
    msg: {
      type: String,
      default: "数据加载中...",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: "#ba1118"
    }
  },
  setup() {
    return {};
  },
});
